import { useState } from "react";
import { SplitTopperProps } from "design-system/components/toppers/split-topper/split-topper";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { SplitSearchTopperEntry } from "frontend/contentful/schema/toppers";
import { useContentful } from "frontend/hooks/use-contentful";
import { fetcher } from "frontend/hooks/use-fetch";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { createMediaAssetProps } from "../primitives/media-asset.props";
import { createCtaListProps } from "../primitives/cta-list.props";
import { createCtaLinkProps } from "../primitives/cta-link.props";
import { ModernSearchHit, SearchResults } from "frontend/types";
import { usePageProps } from "frontend/hooks/use-page-props";
import {
  hasSearchData,
  setdropDownActive,
  setdropDownUrl,
} from "../utils/get-page-search-props";

export const createSplitSearchTopperProps = createContentfulProps<
  SplitSearchTopperEntry,
  SplitTopperProps
>(({ entry, createEditAttributes }) => {
  const { getEntry, getEntryUrl, site } = useContentful();

  const pageProps = usePageProps();
  const [dropdownItems, setDropdownItems] = useState<Array<ModernSearchHit>>(
    [],
  );

  const dropDownActive = setdropDownActive(pageProps);
  const url = setdropDownUrl(pageProps);

  const handleSearch = async (term: string) => {
    if (term.length < 3 || !term || term === "") {
      setDropdownItems([]);
      return;
    }

    const data = (await fetcher({
      url: url,
      params: { term },
    })) as SearchResults;

    if (dropDownActive && hasSearchData(data)) {
      setDropdownItems(data.hits.map((hit) => hit as ModernSearchHit));
    }

    return;
  };

  return {
    theme: entry.fields.theme,
    title: entry.fields.title,
    hiddenTitle:
      pageProps.request.path.substring(1) === site?.fields.slug ||
      (pageProps.request.path === "/" && site?.fields.domain !== "www")
        ? site?.fields.title
        : undefined,
    searchDropdown: {
      placeholder: entry.fields.searchPlaceholder,
      dropdownCta: createCtaLinkProps(entry.fields.searchDropdownCta),
      dropdownFooterLabel: entry.fields.searchDropdownFooterLabel,
      hasDropdown: dropDownActive,
      searchLink: getEntryUrl(pageProps.searchPage),
      handleSearch: (term: string) => {
        void handleSearch(term);
      },
      dropdownItems,
      editAttributes: {
        dropdownFooterLabel: createEditAttributes({
          entry,
          fieldId: "searchDropdownFooterLabel",
        }),
      },
    },
    ctaLabel: entry.fields.ctaLabel,
    ctaList: createCtaListProps(entry.fields.ctaLinks),
    mediaAsset: createMediaAssetProps(entry.fields.media),
    mediaHighlightCaption: entry.fields.mediaHighlightCaption ? (
      <RenderContentfulRichText document={entry.fields.mediaHighlightCaption} />
    ) : undefined,
    mediaLink: getEntry(entry.fields.mediaLink)?.fields.url,

    editAttributes: {
      title: createEditAttributes({ entry, fieldId: "title" }),
      mediaHighlightCaption: createEditAttributes({
        entry,
        fieldId: "mediaHighlightCaption",
      }),
    },
  };
});
